import React from "react";

const ModalImage = ({ image, onClose }) => {
  const handlePrint = () => {
    const printWindow = window.open("", "_blank", "width=600,height=600"); // Open a new window for printing
    printWindow.document.write(`
          <html>
            <head>
              <title>Print Image</title>
              <style>
                body { text-align: center; padding: 20px; }
                img { max-width: 100%; max-height: 80vh; }
              </style>
            </head>
            <body>
              <img src="data:image/jpeg;base64,${image}" alt="Printable Image" />
            </body>
          </html>
        `);
    printWindow.document.close(); // Close the document to apply styles
    printWindow.print(); // Trigger the print dialog
  };

  return (
    <>
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <img
            src={`data:image/jpeg;base64,${image}`}
            alt="No-Image Found"
            className="modal-image"
          />
          <button
            className="btn btn-primary modal-print-btn"
            onClick={handlePrint}
          >
            Print
          </button>
          <button onClick={onClose} className="close-modal-btn">
            X
          </button>
        </div>
      </div>
    </>
  );
};

export default ModalImage;
