import * as React from "react";
import { useEffect, useState } from "react";
import Stepper from "@mui/joy/Stepper";
import Step, { stepClasses } from "@mui/joy/Step";
import StepIndicator, { stepIndicatorClasses } from "@mui/joy/StepIndicator";
import Typography, { typographyClasses } from "@mui/joy/Typography";
import editIcon from "../../assets/images/editIcon.png";
import laptop from "../../assets/images/laptop.jpg";
import logo from "../../assets/images/2k-logo.png";
import close from "../../assets/images/close.png";
import left from "../../assets/images/Chevron Left.png";
import Carousel from "react-simply-carousel";
import call from "../../assets/images/call.png";
import { useNavigate } from "react-router-dom";
import UserPic from "../../assets/images/userpic.png";
import phone from "../../assets/images/phone.png";
import deleteIcon from "../../assets/images/delete.png";
import shareIcon from "../../assets/images/shareIcon.png";
import sandtimer from "../../assets/images/sandtimer.png";
import right from "../../assets/images/Chevron Right.png";
import axios from "axios";
import { useMutation, useQuery } from "react-query";

const deleteItem = async (id) => {
  await axios.delete(`${process.env.REACT_APP_HOST}/requests/req/delete/${id}`);
};
const getViewTech = async () => {
  const response = await axios.get(
    // "https://api.2kvirtualworld.com/CRM/technicians"
    `${process.env.REACT_APP_HOST}/technicians`
  );
  return response?.data;
};

const Popup = ({ isOpen, onClose, type, data, tableData, refetch }) => {
  const navigate = useNavigate();
  const popupRef = React.useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const [deleteID, setDeleteID] = useState("");
  const [singletechdetails, setSingletechdetails] = useState({});
  const [cusnum, setCusnum] = useState(false);
  const [technum, setTechnum] = useState(false);
  const [userdetails, setUserdetails] = useState({});
  const [popupState, setPopupState] = React.useState({
    deletepopup: false,
    deletesuccess: false,
    techSlide: false,
    techAssign: false,
    trackloc: false,
    phoneCall: false,
    technicianCall: false,
    trackCall: false,
  });
  const [callState, setCallState] = useState({
    altcall: false,
    orgcall: false,
  });
  // console.log("data", data);
  // const getAssignTech = async () => {
  //   const response = await axios.get(
  //     // "https://api.2kvirtualworld.com/CRM/technicians"
  //     `${process.env.REACT_APP_HOST}/assignTechnicianRequest/2Kv00003`
  //   );
  //   return response.data;
  // };

  const getAssignTech = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST}/assignTechnicianRequest/${data?.requestID}`
      );
      // Handle the response data as needed
      setUserdetails(response?.data);
    } catch (err) {
      console.error(err);
    }
  };

  // Function to make the API call for assigning a technician
  const assignTechnician = async (formData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST}/assignTechnicianRequest`,
        formData
      );
      return response.data;
    } catch (error) {
      console.error("Error in assignTechnician:", error);
      throw error; // Rethrow error for handling in useMutation
    }
  };
  // useMutation hook for assigning technician
  const { mutate, isLoading, isSuccess, isError, error } = useMutation(
    assignTechnician,
    {
      onSuccess: (data) => {
        console.log("Technician assigned successfully:", data);
        setPopupState({ techAssign: true }); // Assuming `setPopupState` is defined elsewhere
      },
      onError: (error) => {
        const { status, response } = error;
        if (status == 400) {
          alert(response?.data);
        }
      },
    }
  );

  const { data: techdetails } = useQuery("getViewTech", getViewTech);
  // console.log("sffgg->", techdetails);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        // Reset all popup states when clicking outside
        setPopupState({
          deletepopup: false,
          deletesuccess: false,
          techSlide: false,
          techAssign: false,
          trackloc: false,
          phoneCall: false,
          technicianCall: false,
          trackCall: false,
        });
        onClose();
      }
    };

    if (isOpen) {
      window.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  // const result =
  //   techdetails &&
  //   techdetails?.filter((item) => {
  //     const department = item?.department?.toLowerCase();
  //     const product = data?.serviceName?.toLowerCase() || "";

  //     if (!department) return false;

  //     return (
  //       department === product ||
  //       department?.startsWith(product) ||
  //       department?.includes(product)
  //     );
  //   });
  const result =
    techdetails &&
    techdetails?.filter((item) => {
      const department = item?.department?.toLowerCase();
      const product = data?.serviceName?.toLowerCase() || "";

      if (!department) return false;

      return (
        department === product ||
        department?.startsWith(product) ||
        department?.includes(product)
      );
    });

  // If matched technicians are found, return the matched ones, otherwise return all technicians
  const finalResult = result && result?.length > 0 ? result : techdetails;

  // Mutation to handle technician deletion

  const mutation = useMutation(deleteItem, {
    onSuccess: () => {
      refetch();
      // setPopupState({ isOpen: true, type: "success", data: null });
    },
    onError: (err) => {
      console.error("Error deleting technician:", err);
    },
  });

  // Confirm the deletion

  const handleDeleteConfirmation = () => {
    if (deleteID) {
      mutation.mutate(deleteID);
      refetch();
      setPopupState({
        deletepopup: false,
        deletesuccess: true,
        techSlide: false,
        techAssign: false,
      });
    }
  };
  if (!isOpen) return null;

  // Function to assign technician
  const assignApi = (tech) => {
    const formValue = {
      assignedRequest: data, // Assuming `data` is available in this scope
      assignedTechnician: tech,
    };

    // Make sure formValue is valid before calling mutate
    if (
      formValue &&
      formValue.assignedRequest &&
      formValue.assignedTechnician
    ) {
      mutate(formValue);
    } else {
      console.error("Form value is invalid", formValue);
    }
  };

  return (
    <div className="popup-overlay">
      {type == "New Request" &&
        !popupState?.deletepopup &&
        !popupState?.techSlide &&
        !popupState?.deletesuccess &&
        !popupState?.techAssign &&
        !popupState?.phoneCall &&
        !popupState?.technicianCall &&
        data && (
          <>
            <div
              className="popup-content"
              ref={popupRef}
              style={{ maxWidth: "650px" }}
            >
              <div className="close-icon-block" onClick={onClose}>
                <img src={close} alt="Close" className="search-icon" />
              </div>
              <div className="popup-box">
                <div className="tech-details">
                  <div className="popup-heading-block">
                    <div className="popup-logo-block">
                      <img src={logo} alt="no-image" className="crm-image" />
                    </div>
                    <span className="popup-logo-heading">2K SERVICE</span>
                  </div>
                  <div className="popup-profile-block">
                    <div className="popup-profile">
                      <img src={UserPic} alt="no-image" className="crm-image" />
                    </div>
                  </div>
                  <p className="tech-user-details">CUSTOMER NAME</p>
                  <p
                    className="tech-user-details"
                    style={{ fontWeight: "700", fontSize: "15px" }}
                  >
                    {(data && data?.firstName) || ""}{" "}
                    {(data && data?.lastName) || ""}
                  </p>
                  <p className="tech-user-details">
                    MOBILE NUMBER: {(data && data?.mobileNo) || ""}
                  </p>
                  <p className="tech-user-details">
                    ADDRESS: {data?.address || ""}
                  </p>
                  {/* <p className="tech-user-details">EMAIL: raja@gmail.com</p> */}
                </div>
                <div className="request-details d-flex flex-column justify-content-around">
                  <div>
                    <div className="request-user-details mt-3">
                      <p className="request-key">REQUEST ID</p>
                      <p className="request-value">
                        : {(data && data?.requestID) || ""}
                      </p>
                    </div>
                    <div className="request-user-details">
                      <p className="request-key">SERVICE NAME</p>
                      <p className="request-value">
                        : {(data && data?.serviceName) || ""}
                      </p>
                    </div>
                    <div className="request-user-details">
                      <p className="request-key">PRODUCT NAME</p>
                      <p className="request-value">
                        : {(data && data?.product) || ""}
                      </p>
                    </div>
                    <div className="request-user-details">
                      <p className="request-key">MODEL NAME</p>
                      <p className="request-value">
                        : {(data && data?.modelName) || ""}
                      </p>
                    </div>
                    <div className="request-user-details">
                      <p className="request-key">ISSUE</p>
                      <p className="request-value">
                        : {(data && data?.issue) || ""}
                      </p>
                    </div>
                    <div className="request-user-details">
                      <p className="request-key">IMAGE</p>
                      <p className="request-value">
                        <img
                          src={`data:image/jpeg;base64,${
                            data && data?.productImage
                          }`}
                          alt="no-image"
                          className="popup-image"
                        />
                      </p>
                    </div>
                  </div>
                  <div className="mt-4 mb-3 d-flex justify-content-center gap-2">
                    <button
                      className="popup-btn"
                      onClick={() => {
                        setPopupState({ ...popupState, techSlide: true });
                      }}
                    >
                      ASSIGN
                    </button>
                    <div
                      className="popup-phone"
                      onClick={() => {
                        setPopupState({
                          deletepopup: false,
                          deleteTech: false,
                          deletesuccess: false,
                          techSlide: false,
                          techAssign: false,
                          trackloc: false,
                          phoneCall: true,
                        });
                      }}
                    >
                      <img src={phone} alt="no-image" className="crm-image" />
                    </div>
                    <div
                      className="popup-delete"
                      onClick={() => {
                        // Only set deletepopup to true
                        setPopupState({
                          deletepopup: true,
                          deletesuccess: false,
                          techSlide: false,
                          techAssign: false,
                        });
                        setDeleteID(data?.requestID);
                      }}
                    >
                      <img
                        src={deleteIcon}
                        alt="Delete"
                        className="crm-image"
                      />
                    </div>
                    <div
                      className="popup-edit"
                      onClick={() => {
                        navigate("/addRequest", { state: data });
                      }}
                    >
                      <img src={editIcon} alt="Edit" className="crm-image" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      {popupState?.deletepopup && !popupState?.deletesuccess && (
        <>
          <div
            className="popup-content"
            ref={popupRef}
            style={{ maxWidth: "350px", backgroundColor: "#004bcb" }}
          >
            <div
              className="close-icon-block"
              onClick={() => {
                setPopupState({
                  deletepopup: false,
                  deletesuccess: false,
                  techSlide: false,
                  techAssign: false,
                });
              }}
            >
              <img src={close} alt="Close" className="search-icon" />
            </div>
            <div className="delete-popup-block">
              <p className="delete-content">Are You Sure?</p>
              <div className="d-flex gap-2 my-2">
                <button
                  className="delete-popup-btn"
                  onClick={() => {
                    // Confirm delete action

                    handleDeleteConfirmation();
                  }}
                >
                  OK
                </button>
                <button
                  className="delete-popup-btn"
                  onClick={() => {
                    // Cancel delete action
                    setPopupState({
                      deletepopup: false,
                      deletesuccess: false,
                      techSlide: false,
                      techAssign: false,
                      trackloc: false,
                      phoneCall: false,
                      technicianCall: false,
                      trackCall: false,
                    });
                  }}
                >
                  CANCEL
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {popupState?.deletesuccess && (
        <>
          <div
            className="popup-content"
            ref={popupRef}
            style={{ maxWidth: "350px", backgroundColor: "#d4edda" }}
          >
            <div className="delete-sub-popup px-3 py-2">
              <p className="delete-sub-popup-content">
                Request ID has been Deleted
              </p>
              <img src={sandtimer} alt="Loading" />
              <button
                className="delete-sub-popup-btn"
                onClick={() => {
                  setPopupState({
                    techSlide: false,
                    techAssign: false,
                    deletepopup: false,
                    deletesuccess: false,
                  });
                  refetch();
                  onClose();
                }}
              >
                Done
              </button>
            </div>
          </div>
        </>
      )}
      {popupState?.phoneCall && (
        <>
          <div
            className="popup-content"
            ref={popupRef}
            style={{ maxWidth: "350px", backgroundColor: "#004bcb" }}
          >
            <div
              className="close-icon-block"
              onClick={() => {
                setPopupState({
                  deletepopup: false,
                  deleteTech: false,
                  deletesuccess: false,
                  techSlide: false,
                  techAssign: false,
                  trackloc: false,
                  phoneCall: false,
                });
              }}
            >
              <img src={close} alt="Close" className="search-icon" />
            </div>
            <div className="delete-popup-block">
              <p className="delete-content">Who to call?</p>
              <div style={{ width: "25px", height: "25px" }}>
                <img src={call} alt="no-image" className="crm-image" />
              </div>
              {callState?.orgcall && (
                <p className="delete-content ">{data?.mobileNo}</p>
              )}
              {callState?.altcall && (
                <p className="delete-content ">{data?.alternateNo}</p>
              )}
              <div className="d-flex gap-2">
                <button
                  className="popup-calltech-btn"
                  onClick={() => {
                    setCallState({
                      orgcall: !callState?.orgcall,
                    });
                  }}
                >
                  Customer
                </button>
                <button
                  className="popup-calltech-btn"
                  onClick={() => {
                    setCallState({
                      altcall: !callState?.altcall,
                    });
                  }}
                >
                  Alternate Number
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {type == "Pending" &&
        !popupState?.trackloc &&
        !popupState?.techSlide &&
        !popupState?.techAssign &&
        !popupState?.trackCall &&
        !popupState?.technicianCall && (
          <>
            <div
              className="popup-content"
              ref={popupRef}
              style={{ maxWidth: "650px" }}
            >
              <div className="close-icon-block" onClick={onClose}>
                <img src={close} alt="Close" className="search-icon" />
              </div>
              <div className="popup-box">
                <div className="tech-details">
                  <div className="popup-heading-block">
                    <div className="popup-logo-block">
                      <img src={logo} alt="Logo" className="crm-image" />
                    </div>
                    <span className="popup-logo-heading">2K SERVICE</span>
                  </div>
                  <div className="popup-profile-block">
                    <div className="popup-profile">
                      <img src={UserPic} alt="User" className="crm-image" />
                    </div>
                  </div>
                  <p className="tech-user-details">
                    CUSTOMER NAME: {data?.firstName.toUpperCase()}{" "}
                    {data?.lastName.toUpperCase()}
                  </p>
                  <p className="tech-user-details">
                    CUSTOMER ID: {data?.requestID}
                  </p>
                  <p className="tech-user-details">
                    MOBILE NUMBER: {data?.mobileNo}
                  </p>
                  {/* <p className="tech-user-details">EMAIL: {data.email}</p> */}
                </div>
                <div className="request-details">
                  <div className="request-user-details mt-3">
                    <p className="request-key">REQUEST ID</p>
                    <p className="request-value">: {data?.requestID}</p>
                  </div>
                  <div className="request-user-details">
                    <p className="request-key">SERVICE NAME</p>
                    <p className="request-value">: {data?.serviceName}</p>
                  </div>
                  <div className="request-user-details">
                    <p className="request-key">PRODUCT NAME</p>
                    <p className="request-value">: {data?.product}</p>
                  </div>
                  <div className="request-user-details">
                    <p className="request-key">MODEL NAME</p>
                    <p className="request-value">: {data?.modelName}</p>
                  </div>
                  <div className="request-user-details">
                    <p className="request-key">ISSUE</p>
                    <p className="request-value">: {data?.issue}</p>
                  </div>
                  <div className="request-user-details">
                    <p className="request-key">IMAGE</p>
                    <p className="request-value">
                      <img src={laptop} alt="Product" className="popup-image" />
                    </p>
                  </div>
                  <div className="mt-4 mb-3 d-flex justify-content-center gap-2">
                    <button
                      className="popup-btn"
                      onClick={() => {
                        setPopupState({
                          ...popupState,
                          trackloc: true,
                        });
                        getAssignTech();
                      }}
                    >
                      TRACK
                    </button>
                    <div
                      className="popup-phone"
                      onClick={() => {
                        setPopupState({
                          deletepopup: false,
                          deletesuccess: false,
                          techSlide: false,
                          techAssign: false,
                          trackloc: false,
                          phoneCall: false,
                          technicianCall: false,
                          trackCall: true,
                        });
                      }}
                    >
                      <img src={phone} alt="Call" className="crm-image" />
                    </div>
                    <div
                      className="popup-share"
                      onClick={() => {
                        setPopupState({
                          techSlide: true,
                        });
                      }}
                    >
                      <img src={shareIcon} alt="Share" className="crm-image" />
                    </div>
                    <div
                      className="popup-edit"
                      onClick={() => navigate("/addRequest")}
                    >
                      <img src={editIcon} alt="Edit" className="crm-image" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      {popupState?.trackloc && userdetails && (
        <>
          <div
            className="popup-content"
            ref={popupRef}
            style={{ maxWidth: "650px" }}
          >
            <div className="popup-box">
              <div className="close-icon-block">
                <img
                  src={close}
                  alt="no-image"
                  className="search-icon"
                  onClick={() => {
                    setPopupState({
                      ...popupState,
                      trackloc: false,
                    });
                  }}
                />
              </div>
              <div className="track-map-user">
                <div className="popup-heading-block">
                  <div className="popup-logo-block">
                    <img src={logo} alt="no-image" className="crm-image" />
                  </div>
                  <span className="popup-logo-heading">2K SERVICE</span>
                </div>
                <p className="tech-user-details">
                  REQUEST ID : {userdetails?.assignedRequest?.requestID}
                </p>
                <p className="tech-user-details">
                  SERVICE NAME :{" "}
                  {userdetails?.assignedRequest?.serviceName.toUpperCase()}
                </p>
                <p className="tech-user-details">
                  MODEL :{" "}
                  {userdetails?.assignedRequest?.modelName.toUpperCase()}
                </p>
                <p className="tech-user-details">
                  TECHNICIAN NAME :{" "}
                  {userdetails?.assignedTechnician?.firstName.toUpperCase()}
                  &nbsp;
                  {userdetails?.assignedTechnician?.lastName.toUpperCase()}
                </p>
                <p className="tech-user-details">
                  TECHNICIAN ROLE :{" "}
                  {userdetails?.assignedTechnician?.role.toUpperCase()}
                </p>
                <p className="tech-user-details">
                  TECHNICIAN MOBILE :{" "}
                  {userdetails?.assignedTechnician?.mobileNo}
                </p>
              </div>
              {/* <div className="track-details"></div> */}
              <div>
                <div className="timeline-box">
                  <div className="timeline-left">
                    <Stepper
                      orientation="vertical"
                      sx={(theme) => ({
                        "--Stepper-verticalGap": "1.8rem",
                        "--StepIndicator-size": "1rem",
                        "--Step-gap": "0.3rem",
                        "--Step-connectorInset": "0.0rem",
                        "--Step-connectorRadius": "0.5rem",
                        "--Step-connectorThickness": "4px",
                        "--joy-palette-success-solidBg":
                          "var(--joy-palette-success-400)",
                        [`& .${stepClasses.completed}`]: {
                          "&::after": { bgcolor: " #004BCB80" },
                        },
                        [`& .${stepClasses.active}`]: {
                          [`& .${stepIndicatorClasses.root}`]: {
                            border: "4px solid",
                            borderColor: "#fff",
                            boxShadow: `0 0 0 1px ${theme.vars.palette.primary[500]}`,
                          },
                        },
                        [`& .${stepClasses.disabled} *`]: {
                          color: "neutral.softDisabledColor",
                        },
                        [`& .${typographyClasses["title-sm"]}`]: {
                          textTransform: "uppercase",
                          letterSpacing: "1px",
                          fontSize: "10px",
                        },
                      })}
                    >
                      <Step
                        completed
                        indicator={
                          <StepIndicator variant="solid" color="primary">
                            {/* <CheckRoundedIcon /> */}
                          </StepIndicator>
                        }
                      >
                        <div>
                          <Typography level="title-sm">
                            REQUEST VERIFICATION
                          </Typography>
                        </div>
                      </Step>
                      <Step
                        active
                        indicator={
                          <StepIndicator variant="solid" color="primary">
                            {/* <CheckRoundedIcon /> */}
                          </StepIndicator>
                        }
                      >
                        <div>
                          <Typography level="title-sm">
                            REQUEST ACCEPT
                          </Typography>
                        </div>
                      </Step>
                      <Step
                        disabled
                        indicator={<StepIndicator></StepIndicator>}
                      >
                        <div>
                          <Typography level="title-sm">ISSUE FIXED</Typography>
                        </div>
                      </Step>
                    </Stepper>
                  </div>
                  <div className="timeline-right">
                    <div
                      className="popup-phone"
                      onClick={() => {
                        setPopupState({
                          deletepopup: false,
                          deletesuccess: false,
                          techSlide: false,
                          techAssign: false,
                          trackloc: false,
                          phoneCall: false,
                          technicianCall: false,
                          trackCall: true,
                        });
                      }}
                    >
                      <img src={phone} alt="no-image" className="crm-image" />
                    </div>
                    <div
                      className="popup-delete"
                      onClick={() => {
                        // setDeletePopup(true);
                      }}
                    >
                      <img
                        src={deleteIcon}
                        alt="no-image"
                        className="crm-image"
                      />
                    </div>
                    <div
                      className="popup-share"
                      // onClick={() => {
                      //   setPopupstate({
                      //     techAssign: false,
                      //     techcall: false,
                      //     techSlide: true,
                      //     deletepopup: false,
                      //     dltsuccess: false,
                      //   });
                      // }}
                      onClick={() => {
                        setPopupState({
                          techSlide: true,
                        });
                      }}
                    >
                      <img
                        src={shareIcon}
                        alt="no-image"
                        className="crm-image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {popupState?.techSlide &&
        !popupState?.techAssign &&
        techdetails &&
        !popupState?.technicianCall &&
        finalResult && (
          <>
            <div
              className="popup-content py-5"
              ref={popupRef}
              style={{ maxWidth: "700px" }}
            >
              {finalResult && finalResult?.length > 0 ? (
                <>
                  <Carousel
                    containerProps={{
                      style: {
                        width: "100%",
                        justifyContent: "space-between",
                        userSelect: "none",
                      },
                    }}
                    preventScrollOnSwipe
                    swipeThreshold={60} // Fixed typo
                    activeSlideIndex={activeSlide}
                    activeSlideProps={{
                      style: {
                        background: "blue",
                      },
                    }}
                    onRequestChange={setActiveSlide}
                    forwardBtnProps={{
                      children: (
                        <img
                          src={right}
                          alt="Next"
                          style={{ width: 40, height: 40 }}
                        />
                      ),
                      style: {
                        width: 40,
                        height: 40,
                        opacity:
                          activeSlide === finalResult?.length - 1 ? 0.5 : 1, // Reduce opacity when on last slide
                        pointerEvents:
                          activeSlide === finalResult?.length - 1
                            ? "none"
                            : "auto", // Disable int
                        minWidth: 40,
                        alignSelf: "center",
                        border: "none",
                        background: "transparent",
                      },
                      disabled: activeSlide === finalResult?.length - 1, // Disable if last slide
                    }}
                    backwardBtnProps={{
                      children: (
                        <img
                          src={left}
                          alt="Previous"
                          style={{ width: 40, height: 40 }}
                        />
                      ),
                      style: {
                        width: 40,
                        height: 40,
                        opacity: activeSlide === 0 ? 0.5 : 1, // Reduce opacity when on the first slide
                        pointerEvents: activeSlide === 0 ? "none" : "auto", // Disable interaction when on the first slide
                        minWidth: 40,
                        alignSelf: "center",
                        border: "none",
                        background: "transparent",
                      },
                      disabled: activeSlide === 0, // Disable if first slide
                    }}
                    itemsToShow={1}
                    speed={400}
                    centerMode
                  >
                    {finalResult?.map((item, index) => (
                      <div
                        key={index}
                        className="d-flex"
                        style={{
                          width: 600,
                          height: "auto",
                          textAlign: "center",
                          boxSizing: "border-box",
                          borderRadius: "10px",
                        }}
                      >
                        <div className="tech-details">
                          <div className="popup-heading-block">
                            <div className="popup-logo-block">
                              <img
                                src={logo}
                                alt="Logo"
                                className="crm-image"
                              />
                            </div>
                            <span className="popup-logo-heading">
                              2K SERVICE
                            </span>
                          </div>
                          <div className="popup-profile-block">
                            <div className="popup-profile">
                              {/* <img
                                src={UserPic}
                                alt="User"
                                className="crm-image"
                              /> */}
                              <img
                                src={`data:image/jpeg;base64,${
                                  item && item?.photo
                                }`}
                                alt="no-image"
                                className="crm-image"
                              />
                            </div>
                          </div>
                          <p className="tech-user-details">TECHNICIAN NAME</p>
                          <p
                            className="tech-user-details"
                            style={{ fontSize: "16px", color: "yellow" }}
                          >
                            {item?.firstName?.toUpperCase()}{" "}
                            {item?.lastName?.toUpperCase()}
                          </p>
                          <p className="tech-user-details">
                            TECHNICIAN ID : {item?.employeeID}
                          </p>
                          <p className="tech-user-details">
                            MOBILE NUMBER: {item?.mobileNo}
                          </p>
                          <p className="tech-user-details">
                            EMAIL: {item?.emailId}
                          </p>
                        </div>
                        <div className="request-details">
                          {/* <div className="d-flex flex-column px-2"> */}
                          <div
                            className="tech-icon-block"
                            style={{
                              cursor: "pointer",
                              position: "absolute",
                              right: "7px",
                            }}
                            onClick={() => {
                              setPopupState({
                                ...popupState,
                                techSlide: false,
                              });
                            }}
                          >
                            <img
                              src={close}
                              alt="Close"
                              className="search-icon"
                            />
                          </div>
                          <div className="row px-1 pt-5">
                            <div className="col-6 tech_key">TECHNICIAN ID</div>
                            <div className="col-6 tech_value">
                              {" "}
                              : {item?.employeeID}
                            </div>
                          </div>
                          <div className="row px-1">
                            <div className="col-6 tech_key">DEPARTMENT</div>
                            <div className="col-6 tech_value">
                              {" "}
                              : {item?.department}
                            </div>
                          </div>
                          <div className="row px-1">
                            <div className="col-6 tech_key">ROLE</div>
                            <div className="col-6 tech_value">
                              {" "}
                              : {item?.role}
                            </div>
                          </div>
                          <div className="row px-1">
                            <div className="col-6 tech_key">
                              SERVICE PERCENTAGE
                            </div>
                            <div className="col-6 tech_value">
                              {" "}
                              : {item?.technicianPercentage}
                            </div>
                          </div>
                          <div className="row px-1">
                            <div className="col-6 tech_key">
                              MATERIAL PERCENTAGE
                            </div>
                            <div className="col-6 tech_value">
                              {" "}
                              : {item?.materialPercentage}
                            </div>
                          </div>
                          <div className="row px-1">
                            <div className="col-6 tech_key">
                              COUNT OF REQUEST
                            </div>
                            <div className="col-6 tech_value"> : 50</div>
                          </div>
                          <div className="row px-1">
                            <div className="col-6 tech_key">
                              COMPLETED REQUEST
                            </div>
                            <div className="col-6 tech_value"> : 51</div>
                          </div>
                          <div className="row px-1">
                            <div className="col-6 tech_key">STATUS</div>
                            <div className="col-6 tech_value"> : ACTIVE</div>
                          </div>
                          {/* <div className="request-user-details mt-3">
                            <p className="request-key">TECHNICIAN ID</p>
                            <p className="request-value">
                              : {item?.employeeID}
                            </p>
                          </div> */}
                          {/* <div className="request-user-details">
                            <p className="request-key">DEPARTMENT</p>
                            <p className="request-value">
                              : {item?.department}
                            </p>
                          </div> */}
                          {/* <div className="request-user-details">
                            <p className="request-key">ROLE </p>
                            <p className="request-value">: {item?.role}</p>
                          </div> */}
                          {/* <div className="request-user-details">
                            <p className="request-key">COUNT OF REQUEST</p>
                            <p className="request-value">: 50</p>
                          </div> */}
                          {/* <div className="request-user-details">
                            <p className="request-key">COMPLETED REQUEST</p>
                            <p className="request-value">: 51</p>
                          </div> */}
                          {/* <div className="request-user-details">
                            <p className="request-key">STATUS</p>
                            <p className="request-value">ACTIVE</p>
                          </div> */}
                          <div className="mt-4 mb-3 d-flex justify-content-center gap-2">
                            <button
                              className="popup-btn"
                              onClick={() => {
                                // console.log("item", item);
                                assignApi(item);
                              }}
                            >
                              ASSIGN
                            </button>
                            <div
                              className="popup-phone"
                              onClick={() => {
                                setPopupState({
                                  deletepopup: false,
                                  deleteTech: false,
                                  deletesuccess: false,
                                  techSlide: false,
                                  techAssign: false,
                                  trackloc: false,
                                  technicianCall: true,
                                });
                                setSingletechdetails(item);
                              }}
                            >
                              <img
                                src={phone}
                                alt="no-image"
                                className="crm-image"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Carousel>
                </>
              ) : (
                <>
                  <div
                    className="d-flex justify-content-center align-items-center py-5"
                    style={{ background: "#004bcb", borderRadius: "10px" }}
                  >
                    <p
                      style={{
                        color: "white",
                        fontWeight: "700",
                        fontSize: "24px",
                      }}
                    >
                      NO TECHNICIANS FOUNDS
                    </p>
                  </div>
                </>
              )}
            </div>
          </>
        )}

      {popupState?.techAssign && (
        <>
          <div
            className="popup-content"
            ref={popupRef}
            style={{ maxWidth: "350px", backgroundColor: "#d4edda" }}
          >
            <div className="delete-sub-popup px-3 py-2">
              <p className="delete-sub-popup-content m-1">
                ASSIGN TO TECHNICIAN
              </p>
              <p className="m-1 assign-tech">WAIT FOR SOME TIMES</p>
              <img src={sandtimer} />
              <button
                className="delete-sub-popup-btn m-1"
                onClick={() => {
                  setPopupState({
                    techAssign: false,
                    techSlide: false,
                    deletepopup: false,
                    deletesuccess: false,
                  });
                  onClose();
                }}
              >
                Done
              </button>
            </div>
          </div>
        </>
      )}

      {popupState?.technicianCall && (
        <>
          <div
            className="popup-content"
            ref={popupRef}
            style={{ maxWidth: "350px", backgroundColor: "#004bcb" }}
          >
            <div
              className="close-icon-block"
              onClick={() => {
                setPopupState({
                  deletepopup: false,
                  deleteTech: false,
                  deletesuccess: false,
                  techSlide: true,
                  techAssign: false,
                  trackloc: false,
                  phoneCall: false,
                  technicianCall: false,
                });
              }}
            >
              <img src={close} alt="Close" className="search-icon" />
            </div>
            <div className="delete-popup-block">
              <p className="delete-content">Who to call?</p>
              <div style={{ width: "25px", height: "25px" }}>
                <img src={call} alt="no-image" className="crm-image" />
              </div>
              <p className="delete-content ">{singletechdetails?.mobileNo}</p>
              <div className="d-flex gap-2">
                <button
                  className="popup-call-btn"
                  onClick={() => {
                    setPopupState({
                      deletepopup: false,
                      deleteTech: false,
                      deletesuccess: false,
                      techSlide: false,
                      techAssign: false,
                      trackloc: false,
                      technicianCall: false,
                    });
                  }}
                >
                  Technician
                </button>
                {/* <button className="popup-call-btn" onClick={() => onClose}>
                      Technician
                    </button> */}
              </div>
            </div>
          </div>
        </>
      )}

      {popupState?.trackCall && (
        <>
          <div
            className="popup-content"
            ref={popupRef}
            style={{ maxWidth: "350px", backgroundColor: "#004bcb" }}
          >
            <div
              className="close-icon-block"
              onClick={() => {
                setPopupState({
                  deletepopup: false,
                  deleteTech: false,
                  deletesuccess: false,
                  techSlide: false,
                  techAssign: false,
                  trackloc: false,
                  phoneCall: false,
                  technicianCall: false,
                  trackCall: false,
                });
                setCusnum(false);
                setTechnum(false);
              }}
            >
              <img src={close} alt="Close" className="search-icon" />
            </div>
            <div className="delete-popup-block">
              <p className="delete-content">Who to call?</p>
              <div style={{ width: "25px", height: "25px" }}>
                <img src={call} alt="no-image" className="crm-image" />
              </div>
              <div className="d-flex flex-column gap-2 align-item-center">
                {cusnum ? (
                  <p className="delete-content">
                    {userdetails?.assignedRequest?.mobileNo}
                  </p>
                ) : (
                  <></>
                )}
                {technum ? (
                  <p className="delete-content">
                    {userdetails?.assignedTechnician?.mobileNo}
                  </p>
                ) : (
                  <></>
                )}
              </div>
              <div className="d-flex gap-2">
                <button
                  className="popup-call-btn"
                  onClick={() => {
                    // setPopupState({
                    //   deletepopup: false,
                    //   deleteTech: false,
                    //   deletesuccess: false,
                    //   techSlide: false,
                    //   techAssign: false,
                    //   trackloc: false,
                    //   technicianCall: false,
                    //   trackCall: false,
                    // });
                    setCusnum(!cusnum);
                  }}
                >
                  Customer
                </button>
                <button
                  className="popup-call-btn"
                  onClick={() => {
                    // setPopupState({
                    //   deletepopup: false,
                    //   deleteTech: false,
                    //   deletesuccess: false,
                    //   techSlide: false,
                    //   techAssign: false,
                    //   trackloc: false,
                    //   technicianCall: false,
                    //   trackCall: false,
                    // });
                    setTechnum(!technum);
                  }}
                >
                  Technician
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {/* COMPLETED */}

      {type == "Completed" && data && (
        <>
          <div
            className="popup-content"
            ref={popupRef}
            style={{ maxWidth: "650px" }}
          >
            <div className="close-icon-block" onClick={onClose}>
              <img src={close} alt="Close" className="search-icon" />
            </div>
            <div className="popup-box">
              <div className="tech-details">
                <div className="popup-heading-block">
                  <div className="popup-logo-block">
                    <img src={logo} alt="no-image" className="crm-image" />
                  </div>
                  <span className="popup-logo-heading">2K SERVICE</span>
                </div>
                <div className="popup-profile-block">
                  <div className="popup-profile">
                    <img src={UserPic} alt="no-image" className="crm-image" />
                  </div>
                </div>
                <p className="tech-user-details">
                  CUSTOMER NAME : {data?.firstName.toUpperCase()}&nbsp;
                  {data?.lastName.toUpperCase()}
                </p>
                <p className="tech-user-details">
                  CUSTOMER ID : {data?.requestID}
                </p>
                <p className="tech-user-details">
                  MOBILE NUMBER : {data?.mobileNo}
                </p>
                {/* <p className="tech-user-details">EMAIl : </p> */}
              </div>
              <div className="request-details">
                <div className="request-user-details mt-3">
                  <p className="request-key">REQUEST ID </p>{" "}
                  <p className="request-value">: {data?.requestID}</p>
                </div>
                <div className="request-user-details">
                  <p className="request-key">SERVICE NAME </p>{" "}
                  <p className="request-value">
                    : {data?.serviceName.toUpperCase()}
                  </p>
                </div>
                <div className="request-user-details">
                  <p className="request-key">PRODUCT NAME </p>{" "}
                  <p className="request-value">
                    {" "}
                    :{data?.product.toUpperCase()}
                  </p>
                </div>
                <div className="request-user-details">
                  <p className="request-key">MODEL NAME </p>{" "}
                  <p className="request-value">
                    : {data?.modelName.toUpperCase()}
                  </p>
                </div>
                <div className="request-user-details">
                  <p className="request-key">ISSUE </p>{" "}
                  <p className="request-value">: {data?.issue.toUpperCase()}</p>
                </div>
                <div className="request-user-details">
                  <p className="request-key">IMAGE </p>{" "}
                  <p className="request-value">
                    <img
                      src={`data:image/jpeg;base64,${data?.photo}`}
                      alt="no-image"
                      className="popup-image"
                    />
                  </p>
                </div>
                <div className="mt-4 mb-3 d-flex justify-content-center gap-2">
                  <button
                    className="popup-btn"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    {type == "Completed" ? "Completed" : ""}
                  </button>

                  <div
                    className="popup-edit"
                    onClick={() => {
                      navigate("/addRequest", { state: data });
                    }}
                  >
                    <img src={editIcon} alt="no-image" className="crm-image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Popup;
