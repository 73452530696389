import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import closeIcon from "../../src/common/assets/images/close.png";
import { useMutation } from "react-query";
import moment from "moment";

const PopupBox = ({ isOpen, onClose, formData }) => {
  const { control, handleSubmit, setValue, reset, watch, getValues } =
    useForm();

  const totalAmtValue = watch("totalAmt", formData?.totalAmount || "");
  const { currentAmount, balanceAmount } = watch();

  const navigate = useNavigate();
  // console.log("formData", formData);

  useEffect(() => {
    let balancepay = Number(totalAmtValue) - Number(currentAmount);
    setValue("balanceAmount", Number(balancepay));
    setValue(
      "paymentStatus",
      Number(balanceAmount) == 0 ? "Completed" : "Pending"
    );
  }, [totalAmtValue, currentAmount, setValue, balanceAmount]);
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    async (postData) => {
      try {
        console.log("postData", postData);

        const response = await axios.post(
          `${process.env.REACT_APP_HOST}/requests`,
          postData
        );
        // setConfirmAlert(true);
        // setDeleteConfirmAlert(false);
        navigate("/");
        // deleteConfirmAlert(true);
        return response.data;
      } catch (error) {
        console.error("Error in mutation:", error);

        throw error;
      }
    }
  );

  const onSubmit = (data) => {
    const formValue = {
      firstName: formData?.firstName,
      lastName: formData?.lastName,
      address: formData?.address,
      mobileNo: formData?.mobileNo,
      alternateNo: formData?.alternateNo,
      modelName: formData?.modelName,
      product: formData?.product,
      issue: formData?.issue,
      photo: "",
      serviceName: formData?.serviceName,
      selectSlot: formData?.selectSlot,
      totalAmount: formData?.totalAmount,
      actualAmount: formData?.actualAmt,
      netAmount: formData?.netAmt,
      technicianShare: formData?.technicianShare,
      ownerShare: formData?.ownerShare,
      place: formData?.place,
      pincode: formData?.pincode,
      referenceName: formData?.referenceName,
      referencePhoneNumber: formData?.referencePhoneNumber,
      appointmentDate: moment(formData?.appointmentDate).format("YYYY-MM-DD"),
      appointmentTime: formData?.appointmentTime,
      transportCharges: formData?.transportCharges,
      otherCharges: formData?.otherCharges,
      purchaseMaterialCost: formData?.purchaseMaterialCost,
      transportKM: formData?.transportKM,
      transportAmount: formData?.transportAmount,
      brandName: formData?.brandName,
      serialNo: formData?.serialNo,
      replaceMaterialName: formData?.replaceMaterialName,
      replaceMaterialCharge: formData?.replaceMaterialCharge,
      serviceCharge: formData?.serviceCharge,
      productImage: formData?.productImage,
      modelImage: formData?.modelImage,
      serialImage: formData?.serialImage,
      otherDetails: formData?.otherDetails,
      totalTechnicianShare: formData?.totalTechnicianShare,
      amcCharge: formData && formData?.amcCharge,
      amcDate:
        moment(formData && formData?.amcDate).format("YYYY-MM-DD") || null,
      endDate: moment(formData && formData?.endDate).format("YYYY-MM-DD"),
      amcTime: (formData && formData?.amcTime) || null,
      amcYear: (formData && formData?.amcYear) || null,
      amc: (formData && formData?.amc) || null,
      warrantyDetails: (formData && formData?.warrantyDetails) || null,
      clearData: formData?.clearData,
      clearPayment: formData?.clearPayment,
      modelType: formData?.modelType,
      productCharge: formData?.productCharge,
      currentAmount: data?.currentAmount,
      balanceAmount: data?.balanceAmount,
      paymentStatus: data?.paymentStatus,
    };
    if (formValue) {
      mutate(formValue);
    }
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-popup" onClick={(e) => e.stopPropagation()}>
          <img
            src={closeIcon}
            alt="no-image"
            style={{
              width: "25px",
              height: "25px",
              cursor: "pointer",
              position: "absolute",
              top: "5px",
              right: "5px",
            }}
            onClick={() => onClose()}
          />

          <span className="popup-payment-heading">BEFORE PAYMENT : </span>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row my-3 d-flex align-items-center">
              <div className="col-3">
                <label className="popup-label">Total Amount</label>
              </div>
              <div className="col-3">
                <Controller
                  control={control}
                  name="totalAmt"
                  defaultValue={formData?.totalAmount || ""} // Set initial value from formData
                  render={({ field }) => (
                    <input
                      {...field}
                      className="popop-input-field"
                      type="number"
                      disabled
                    />
                  )}
                />
              </div>
              <div className="col-3">
                <label className="popup-label">Amount To Pay</label>
              </div>
              <div className="col-3">
                <Controller
                  control={control}
                  name="currentAmount"
                  render={({ field }) => (
                    <input
                      {...field}
                      className="popop-input-field"
                      type="number"
                    />
                  )}
                />
              </div>
            </div>
            <div className="row my-3 d-flex align-items-center">
              <div className="col-3">
                <label className="popup-label">Balance Amount</label>
              </div>
              <div className="col-3">
                <Controller
                  control={control}
                  name="balanceAmount"
                  render={({ field }) => (
                    <input
                      {...field}
                      className="popop-input-field"
                      type="number"
                    />
                  )}
                  // rules={{ required: true }}
                />
              </div>
              <div className="col-3">
                <label className="popup-label">Payment Type</label>
              </div>
              <div className="col-3">
                <Controller
                  name="paymentStatus"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <select {...field} className="popop-input-field">
                      <option value=""></option>
                      <option value="Pending">Pending</option>
                      <option value="Completed">Completed</option>
                    </select>
                  )}
                />
              </div>
            </div>
            <div className="row mt-5 mb-3">
              <div className="col-12 text-center">
                <button className="popup-input-submit">
                  {isLoading ? (
                    <div className="spinner-border text-light" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    "SUBMIT"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default PopupBox;
