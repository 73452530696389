import React, { useState } from "react";
import loginlogo from "../../assets/images/2k-logo.png";
import visibilityoff from "../../assets/images/visibility_off.png";
import visibilityon from "../../assets/images/visibility_on.png";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import crmlogo from "../../assets/images/crm_logo.png";
import InputField from "../inputfield/InputField";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import axios from "axios";
const Login = () => {
  const { control, handleSubmit, watch } = useForm();
  const [isShow, setIsShow] = useState(true);
  const navigate = useNavigate();
  const { username, password } = watch();
  const [error, setError] = useState();
  console.log("DATTA", username, password);
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    (postData) =>
      axios.post(
        // `https://api.2kvirtualworld.com/CRM/registers/login`,
        `${process.env.REACT_APP_HOST}/registers/login`,
        postData
      ),
    {
      onSuccess: (data) => {
        sessionStorage.setItem("login", true);
        // console.log("data", data && data?.data && data && data?.data?.register);

        let registerData = JSON.stringify(
          data && data?.data && data && data?.data?.register
        );
        sessionStorage.setItem("userData", registerData);

        window.location.reload();
        navigate("/");
      },
      onError: (error) => {
        setError(error);
        console.error("Mutation failed:", error);
      },
    }
  );
  const onSubmit = (data) => {
    // console.log("data", data);
    mutate(data);
  };
  return (
    <>
      <div className="loginMainBlock">
        <div className="login-image-block">
          <div className="login-image-container">
            <img src={crmlogo} alt="no-image" className="crm-image" />
          </div>
        </div>
        <div className="login-input-container">
          <form
            className="login-input-main-block py-4"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="login-logo-block">
              <div className="login-logo">
                <img src={loginlogo} alt="no-image" className="crm-image" />
              </div>
              <h6 className="login-logo-heading">2K SERVICE</h6>
            </div>
            <div className="loginInputFieldBlock">
              <div className="login-input-block">
                <label className="login-label">ADMIN ID</label>
                <div>
                  <Controller
                    control={control}
                    name="username"
                    render={(field) => (
                      <InputField
                        {...field}
                        type="text"
                        login_input_field="loginInputField"
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>
              </div>
            </div>
            <div className="loginInputFieldBlock">
              <div className="login-input-block">
                <label className="login-label">PASSWORD</label>
                <div className="loginPasswordBlock">
                  <Controller
                    control={control}
                    name="password"
                    render={(field) => (
                      <InputField
                        {...field}
                        type={`${!isShow ? "text" : "password"}`}
                        login_input_field="loginInputField"
                      />
                    )}
                    rules={{ required: true }}
                  />
                  <div
                    className="passwordBlock"
                    onClick={() => {
                      setIsShow(!isShow);
                    }}
                  >
                    {!isShow ? (
                      <img
                        src={visibilityon}
                        className="crm-image"
                        alt="no-image"
                      />
                    ) : (
                      <img
                        src={visibilityoff}
                        className="crm-image"
                        alt="no-image"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="loginInputFieldBlock m-0 p-0">
              {isError && (
                <p
                  style={{ color: "red", fontWeight: "500" }}
                  className="p-0 m-0"
                >
                  {error &&
                    error?.response &&
                    error?.response?.data &&
                    error?.response?.data?.message}
                </p>
              )}
            </div>
            <div className="loginInputFieldBlock mb-3">
              <button className="login-btn">
                {isLoading ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : (
                  "login"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
